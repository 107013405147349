<template>
  <div id="app" class="container">
    <header>
      <div class="header-frame">
        <div class="header-left">
          <h1>FaceChecker</h1>
          <span>顔の左右非対称性をチェック</span>
        </div>
        <div class="header-right">
          <a href="https://yurulica.com/">En</a>
          <a href="https://yurulica.com/">Cn</a>
        </div>
      </div>
    </header>
    <main class="main-frame">
      <section v-show="currentState === state.Capture || currentState === state.Modification" class="camera">
        <div class="camera-area">
          <div class="videoContainer">
            <video v-show="currentState === state.Capture" class="child" ref="video" disablePictureInPicture playsInline muted></video>
            <canvas v-show="currentState === state.Modification" class="child" ref="canvas"></canvas>
          </div>
          <div class="line-area">
            <div class="camera-center-circle">
            </div>
            <div class="camera-center-bar">
            </div>
          </div>
        </div>
        <div v-if="currentState === state.Capture" class="capture-area">
          <div class="text-area">
            <p>顔の中心を中心線に合わせて、ボタンを押してください。</p>
            <hr>
          </div>
          <div>
            <button class="camera-button" @click="capture"></button>
          </div>
        </div>
        <div v-if="currentState === state.Modification" class="modify-area">
          <div class="text-area">
            <p>写真の位置を調整してください</p>
          </div>
          <div>
            <label>
              <input type="range" class="slider" min="-450" max="450" v-model="rotate" @input="draw">
            </label>
          </div>
          <div>
            <button class="camera-button" @click="send"></button>
          </div>
        </div>
      </section>
      <section v-if="currentState === state.Loading" class="camera">
        Loading...
      </section>
      <section v-if="currentState === state.Result" class="camera">
        <img :src="image" alt="image">
        <div v-for="result in results" :key="result">
          {{result}}
        </div>
        <button class="camera-button" @click="reset"></button>
      </section>
      <section v-if="currentState === state.Error" class="camera">
        <div>
          {{error}}
        </div>
        <button class="camera-button" @click="reset"></button>
      </section>
    </main>
    <footer>

    </footer>
  </div>
</template>

<script>
import axios from 'axios'
import { saveAs } from 'file-saver'

// const location = document.location
// const url = "https://" + location.hostname + ":8100/data/upload"
// const url = 'http://localhost:8100/data/upload'
const url = 'https://facechecker.api.yurulica.com/data/upload'

const state = {
  Capture: 0,
  Modification: 1,
  Loading: 2,
  Result: 3,
  Error: 4
};

export default {
  name: 'FaceCheck',
  data: function() {
    return {
      image: null,
      currentState: state.Capture,
      rotate: 0,
      results: [],
      error: ""
    }
  },
  computed: {
    state:()=> state
  },
  mounted: function () {
    this._video = this.$refs.video;
    this._canvas = this.$refs.canvas;

    const mouseMove = this.mouseMove.bind(this)
    const touchMove = this.touchMove.bind(this)

    this._canvas.addEventListener("mousedown", (e) => {
      e.preventDefault();
      console.log("mousedown");
      this._beforePoint = { x: e.x, y: e.y };
      this._canvas.addEventListener("mousemove", mouseMove);
    });
    this._canvas.addEventListener("touchstart", (e) => {
      e.preventDefault();
      console.log("touchstart");
      this._beforePoint = { x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY };
      this._canvas.addEventListener("touchmove", touchMove);
    });
    for (const event of ["mouseup", "mouseleave"]) {
      this._canvas.addEventListener(event, () => {
        console.log(event);
        this._canvas.removeEventListener("mousemove", mouseMove);
      });
    }
    for (const event of ["touchend", "touchcancel"]) {
      this._canvas.addEventListener(event, () => {
        console.log(event);
        this._canvas.removeEventListener("touchmove", touchMove);
      });
    }


    this._context = this._canvas.getContext("2d");
    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: "user"
      },
      audio: false,
    }).then(stream => {
      this._video.srcObject = stream;
      this._video.muted = true;
      this._video.playsInline = true;
      this._video.play();
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    async capture() {
      const videoWidth = this._video.videoWidth;
      const videoHeight = this._video.videoHeight;
      this._canvas.width = videoWidth;
      this._canvas.height = videoHeight;
      this._rect = {
        x: 0,
        y: 0,
        width: videoWidth,
        height: videoHeight
      };
      if (videoWidth > videoHeight) {
        const diff = videoWidth - videoHeight;
        this._rect.x = diff * 0.5;
        this._rect.width = videoWidth - diff;
      } else if (videoWidth < videoHeight) {
        const diff = videoHeight - videoWidth;
        this._rect.y = diff * 0.5;
        this._rect.height = videoHeight - diff;
      }
      console.log(`videoWidth: ${videoWidth}, videoHeight: ${videoHeight}`);
      const ctx = this._context;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      console.log("capture");
      console.log(this._rect);
      ctx.drawImage(this._video, 0, 0, videoWidth, videoHeight);

      this._captureImage = new Image();
      this._captureImage.onload = () => {
        this.modification();
      }
      this._captureImage.src = this._canvas.toDataURL();
      this.rotate = 0;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.canvas.width = this._rect.width;
      ctx.canvas.height = this._rect.height;
      ctx.drawImage(this._video, this._rect.x, this._rect.y, this._rect.width, this._rect.height, 0, 0, this._rect.width, this._rect.height);
      this._video.pause();
    },

    modification() {
      this.currentState = state.Modification;
    },

    mouseMove(e) {
      this.drag({x: e.x, y: e.y});
    },

    touchMove(e) {
      this.drag({ x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY });
    },

    drag(point) {
      // console.log("x: " + e.x + ", y: " + e.y);
      // console.log(this._downPoint);
      const diff = {x: point.x - this._beforePoint.x, y: point.y - this._beforePoint.y};
      console.log(diff);
      this._beforePoint = point;
      const rad = (-this.rotate * 0.1) * Math.PI / 180;
      this._rect.x -= (Math.cos(rad) *  diff.x) - (Math.sin(rad) * diff.y);
      this._rect.y -= (Math.sin(rad) *  diff.x) + (Math.cos(rad) * diff.y);
      // this._rect.x -= diff.x;
      // this._rect.y -= diff.y;
      // console.log("drag");
      // console.log(this._rect);
      this.draw();
    },



    draw() {
      const ctx = this._context;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.save();
      ctx.beginPath();
      ctx.translate(ctx.canvas.width * 0.5, ctx.canvas.height * 0.5);
      const rad = (this.rotate * 0.1) * Math.PI / 180;
      ctx.rotate(rad);
      ctx.translate(-ctx.canvas.width * 0.5, -ctx.canvas.height * 0.5);
      // console.log("draw");
      // console.log(this._rect);
      ctx.drawImage(
              this._captureImage,
              Math.max(this._rect.x, 0),
              Math.max(this._rect.y, 0),
              this._rect.x >= 0 ? this._rect.width : this._rect.width + this._rect.x,
              this._rect.y >= 0 ? this._rect.height : this._rect.height + this._rect.y,
              this._rect.x >= 0 ? 0 : -this._rect.x,
              this._rect.y >= 0 ? 0 : -this._rect.y,
              this._rect.x >= 0 ? this._rect.width : this._rect.width + this._rect.x,
              this._rect.y >= 0 ? this._rect.height : this._rect.height + this._rect.y
      );
      // const diffRotate = this._beginRotate - this.rotate;
      // this._beginRotate = this.rotate;
      ctx.restore();
    },

    async send() {
      this.currentState = state.Loading;
      const dataURL = this._canvas.toDataURL("image/png");
      const blob = await this.toBlob(dataURL);
      const data = new FormData();
      data.append("uploadFile", blob, "capture.png");
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      try {
        console.log(this.image);
        const res = await axios.post(url, data, config);

        console.log(res);
        const base64 = res.data.imageBase64;
        console.log(base64.length);
        this.image = "data:image/png;base64," + base64;

        // const bin = atob(base64.replace(/^.*,/, ''));
        // const buffer = new Uint8Array(bin.length);
        // for (let i = 0; i < bin.length; i++) {
        //   buffer[i] = bin.charCodeAt(i);
        // }
        // // Blobを作成
        // const blob = new Blob([buffer.buffer], {
        //   type: 'image/png'
        // });
        // saveAs(blob, "test.png");
        this.results.length = 0;
        for(const [key, value] of Object.entries(res.data.misalignmentData)) {
          this.results.push(`${key}: ${Math.ceil(value * 10000) / 100}%`);
        }
        this.currentState = state.Result;
      } catch (e) {
        console.error(e);
        this.error = e.message;
        this.currentState = state.Error;
      }
    },
    async toBlob(dataUrl) {
      return await (await fetch(dataUrl)).blob()
    },
    async reset() {
      this.currentState = state.Capture;
      this._video.play();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
  font-size: 20px;
  margin: 0;
}
span{
  font-size: 12px;
  margin: 0;
}
.container {
  text-align: center;
}
.main-frame {
  padding-top: 150px;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  text-align: center;
  display: inline-block;
}
.camera-area{
  width: 300px;
  height: 300px;
  border-radius: 300px;
  background-color: gainsboro;
  text-align: center;
  display: inline-block;
  position: relative;
}
.camera-area .videoContainer {
  position: absolute;
   width: 100%;
   height: 100%;
 }
.camera-area .videoContainer .child {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.line-area {
  pointer-events: none;
  width: 100%;
}
.camera-center-circle {
  display: flex;
  width: 175px;
  height: 200px;
  text-align:center;
  line-height: 80px;
  border-radius: 50%;
  border: dashed 3px #ffffff;
  position: absolute;
  margin: 40px 62.5px 50px 62.5px;
}
.camera-center-bar {
  width: 5px;
  height: 100%;
  background-color: #ffffff;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
}
.camera-button{
  margin-top: 100px;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background-color: black;
  cursor: pointer;
}
.main-frame section {
  text-align: center;
}


.slider {
  -webkit-appearance: none;
  appearance: none;
  background-color: #333;
  height: 5px;
  width: 100%;
  border-radius: 6px;
}

.slider:focus,
.slider:active {
   outline: none;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  border: 2px solid rgba(0, 0, 0, .7);
  width: 22px;
  height: 22px;
  display: block;
  background-color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.slider:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, .6);
  transition: .4s;
}
</style>
